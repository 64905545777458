<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<!-- 证书信息 -->
<style scoped>
  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  // import ChangeZsk from "@/components/change-zhengshu.vue"
  import CheckHeader from "@/components/check-header.vue"
  import {
    getCert,
    editCert,
    deleteCert
  } from "@/api/zhengshu/record.js"
  import {
    addZsk,
  } from "@/api/zhengshu/manage.js"

  export default {
    components: {
      Layout,
      // ChangeZsk,
      CheckHeader



    },
    data() {
      return {
        title: " 证书管理/",
        title2: "",
        subTitle: "",
        subTitle2: "",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "证书邮寄",
            active: true
          }
        ],
        showModal: false,
        module: "ZSK",
        exportTitle: "证书库记录导出",
        zhengShu: {},
        addForm: {},
        addZskForm: {},
        tableList: [],
        // 证书状态
        statusLsit: [{
            name: "已查询",
            val: "1",
          },
          {
            name: "未查询",
            val: "0",
          },
        ],
        getWay: [{
            name: "邮寄",
            val: "1"
          },
          {
            name: "自领取",
            val: "2"
          }],
        type:'info',
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          cxzt: "",
          lqfs: "",
          zskid: "",
          keyword: "",
          keyParam: "zjhm"
        },
        keyName: [{
          name: "姓名",
          val: "ksxm",
        },
          {
            name: "证书号",
            val: "zsglh",
          },
          {
            name: "身份证号",
            val: "zjhm",
          },
          {
            name: "考试名称",
            val: "ksmc",
          }
        ],
      };
    },
    methods: {
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      // 证书记录分页
      getList() {
        getCert(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total

          }
        })
      },
      // 删除
      deleteItem(zsid) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteCert(zsid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      success() {
        this.getList()
      },
      // 证书导入
      uploadFile() {
        var _this = this;
        _this.$importZhengshu().show({
          module: this.module,
          zskid: this.pageData.zskid,
          zskmc: this.pageData.zskmc,
          success: this.success
        })
      },
      // 证书导入更新自领取
      uploadFile2() {
        var _this = this;
        _this.$importModal().show({
          title: '导入更新自领取状态',
          module: 'ZSZLQ',
          success: this.success
        })
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showModal = true
        this.type = 'edit'
      },
      showDetails(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showModal = true
        this.type = 'info'
      },
      // 提交
      submit() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        editCert(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message ? res.message : '数据操作成功!',
            })
            this.showModal = false
            this.getList()
          }
        })
      },
      // 添加证书弹窗
      addItem() {
        this.$refs.AddCertKu.show()
      },
      // 添加证书
      addZskList(e) {
        let formDate = JSON.parse(JSON.stringify(e))
        addZsk(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.$refs.AddCertKu.hide()
          }

        })
      },
      // 获取证书库信息
      getZskInfo() {
        if (this.zhengShu) {
          let zhengShu = JSON.parse(this.zhengShu)
          this.title2 = zhengShu.zskmc
          this.pageData.zskid = zhengShu.zskid
          this.getList()
        } else {
          this.$message({
            type: 'success',
            message: '请先选择证书库',
          })
          // let search = {
          //   nf: new Date().getFullYear(),
          // }
          // getZsk(search).then((res) => {
          //   if (res.status) {
          //     this.title2 = res.data[0].zskmc
          //     this.pageData.zskid = res.data[0].zskid
          //     this.getList()
          //   }
          // });
        }
      },

    },
    mounted() {
      // this.getZskInfo()
      this.getList()
    },
    created() {
      // this.zhengShu = this.getStore("zhengshu")
    },


  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :subTitle="subTitle" :subTitle2="subTitle2" :items="items"
      @addItem="addItem" />
    <!-- <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-2">{{ title }} {{title2}}<span class="font-blue ml-2" v-b-modal.showmodal>{{subTitle}}</span>
        <span class="font-blue ml-2" @click="addItem()" style="cursor: pointer;">{{subTitle2}}</span>
      </h4>
      <div class="page-title-right">
        <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
<!--                <input placeholder="关键字" class="h30 form-control border-blue mr-2" maxlength="50" style="width: 10%;"-->
<!--                  v-model="pageData.keyword" />-->
<!--                <el-select class=" mr-2 " style="width: 10%;" clearable placeholder="查询状态" size="small"-->
<!--                  v-model="pageData.cxzt">-->
<!--                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in statusLsit" :key="i">{{item.name}}-->
<!--                  </el-option>-->
<!--                </el-select>-->
                <el-select class=" mr-2 " style="width: 10%;" clearable placeholder="领取方式" size="small"
                  v-model="pageData.lqfs">
                  <el-option :value="item.val" :label="item.name" v-for="(item,i) in getWay" :key="i">{{item.name}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 " style="width:8%;" clearable placeholder="请选择" v-model="pageData.keyName" @change="pageData.keyWord=''"
                           size="small">
                  <el-option v-for="(item, i) in keyName" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 w-10 form-control border-blue mr-2" maxlength="50" clearable
                       v-model="pageData.keyWord" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <a  :href="'/admin/certificateManage'">
                <b-button variant="outline-info" class="flexList  condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>导入证书</b-button>
                </a>
                <el-button size="small" type="primary" plain class="flexList ml-2 h30 " @click="uploadFile2"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>导入更新为自领取状态</el-button>

              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                <tr>
                  <th style="width: 3%;">序号</th>
                  <th style="width: 5%;">姓名</th>
                  <th style="width: 9%;">证件号</th>
                  <th style="width: 15%;">考试名称</th>
                  <th style="width: 12%;">证书号</th>
                  <th style="width: 5%;">级别</th>
                  <th style="width: 8%;">专业</th>
                  <th style="width: 5%;">专业通过</th>
                  <th style="width: 8%;">报名地市名称</th>
                  <th style="width: 5%;">允许邮寄</th>
                  <th style="width: 5%;">邮寄状态</th>
                  <th style="width: 5%;">操作</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">
                  <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                  <td><a href="" class="blue-font">{{obj.ksxm}}</a></td>
                  <td>{{obj.zjhm}}</td>
                  <td>{{obj.ksmc}}</td>
                  <td><a href="javascript:;" @click="showDetails(obj)">{{obj.zsglh}}</a></td>
                  <td>{{obj.bkjbmc}}</td>
                  <td>{{obj.bkzymc}}</td>
                  <td>{{obj.zytg}}</td>
                  <td>{{obj.bmdsmc}}</td>
                  <td>
                    <a href="javascript:;">{{obj.yxyj?'允许':''}}</a>
                  </td>
                  <td>
                    {{obj.lqfs==1?'邮寄':obj.lqfs==2?'自领取':''}}
                  </td>
                  <td class="tab-icon ">
                    <i   v-if="obj.lqfs!==1&&obj.lqfs!==2" class="iconfont icon-edit-two mr-1" @click="editItem(obj)"></i>
<!--                    <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj.zsid)"></i>-->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 切换库 -->
<!--    <ChangeZsk></ChangeZsk>-->
    <!-- 添加证书库 -->

    <!-- 编辑 -->
    <b-modal id="certificate-modal" v-model="showModal" :title="addForm.ksmc" centered title-class="font-24" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>考生姓名：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.ksxm}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>证件号码：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.zjhm}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>出生年月：</label>
              <div class=" col-sm-10 p-0  line1">
                {{addForm.csny}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>&#12288;&#12288;性别：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.xb}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>&#12288;档案号：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.dah}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>证书编号：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.zsglh}}
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="flexList check-distri mb-3"><label>报考级别：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.bkjbmc}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>报考专业：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.bkzymc}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>专业通过：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.zytg}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>考区名称：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.kqmc}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>报名地市：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.bmdsmc}}
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>其他信息：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.json}}
              </div>
            </div>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <div class="flexList check-distri mb-3"><label>查询状态：</label>-->
<!--              <div class=" col-sm-10 p-0  line2">-->
<!--                <select name="" class="form-control form-select pt-1" v-model="addForm.cxzt">-->
<!--                  <option value="" disabled>请选择查询状态</option>-->
<!--                  <option :value="item.val" v-for="(item,i) in statusLsit" :key="i">-->
<!--                    {{item.name}}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-form-item>-->
          <el-form-item v-if="type==='info'">
            <div class="flexList check-distri mb-3"><label>领取方式：</label>
              <div class=" col-sm-10 p-0  line2">
               {{addForm.lqfs===1?'邮寄':addForm.lqfs===2?'自领取':''}}
              </div>
            </div>
          </el-form-item>
          <el-form-item v-else>
            <div class="flexList check-distri mb-3"><label>领取方式：</label>
              <div class=" col-sm-10 p-0  line2">
                <select name="" class="form-control form-select pt-1" v-model="addForm.lqfs">
                  <option value="" disabled>请选择领取方式</option>
                  <option :value="item.val" v-for="(item,i) in getWay" :key="i">
                    {{item.name}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label>&#12288;修改人：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.modifyUser}} {{addForm.modifyTime}}
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="type==='info'">
            <div class="flexList check-distri mb-3"><label>允许邮寄：</label>
              <div class=" col-sm-10 p-0  line2">
                {{addForm.yxyj?'允许':'不允许'}}
              </div>
            </div>
          </el-form-item>
          <el-form-item v-else>
            <div class="flexList check-distri mb-3"><label>允许邮寄：</label>
              <div class=" col-sm-10 p-0  line2">
                <b-form-checkbox v-model="addForm.yxyj" switch class="mt-1 switch-check" :value="true"
                                 :unchecked-value="false">
                </b-form-checkbox>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3   text-center" v-if="type==='edit'">
        <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
                                                @click="submit">确定</button>
          <button type="button" class="btn btn-secondary h30  w-md" @click="showModal = false">取消</button>
        </div>
      </div>
    </b-modal>
    <!-- 批量处理 -->

    <!-- 弹窗结束 -->
  </Layout>
</template>
