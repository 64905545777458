// 证书记录
import {
  getRequest,
  // postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
// 分页查询
export const getCert = params => {
  return getRequest("/yethan/cert/record/listPage", params);
};
// 添加
// export const addCert = params => {
//   return postRequest("/yethan/cert/record", params);
// };
// 修改
export const editCert = params => {
  return putRequest("/yethan/cert/record", params);
};
// 删除
export function deleteCert(zsid) {
  return deleteRequest("/yethan/cert/record/" + zsid);
}

// 证书记录上传校验
export const checkCert = (zskid,params) => {
  return putRequest("/yethan/cert/record/check/"+zskid+"?rollBackKey="+params);
};